header {
  position: fixed;
  background-color: #0c3c60;
  height: 89px;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 20px;
  z-index: 999;
}

.header-container {
  max-width: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left img {
  height: 50px;
  margin-right: 10px;
}

nav {
	text-align: left;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin-right: 40px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s;
}

nav ul li a:hover {
  color: #ddd;
  border-bottom: 2px solid #d9d5d5;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;
}

.menu-toggle {
  display: none;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}

/* Responsive styling for smaller devices */
@media (max-width: 767px) {
  header {
    height: auto;
    padding: 10px;
  }

  .header-container {
    flex-direction: column;
  }

	nav ul {
    display: none;
    flex-direction: column;
  }

  nav ul li {
		margin-top: 10px;
  }

  .menu-toggle {
    display: inline-block;
  }
  
	.open ul {
    display: flex;
    flex-direction: column;
  }
	.header-left h1 {
    font-size: 24px; /* or adjust to your preference */
  }
}