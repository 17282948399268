
html,
body {
	margin: 0;
	padding: 0;
}

main {
	margin-top: 80px;
}

section {
	min-height: 800px;
	/* min-width: 380px;*/
	position: relative;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	padding-bottom: 100px; /* add padding-bottom to create space for footer */
	padding-top: auto;
}

.sectionTitle {
  height: 100%;
  color: dark-gray;
}