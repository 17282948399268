.services {
  background-image: url('../images/bg1.jpg');
	padding-top: 150px;
	height: 700px;
}

.services-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  /*padding: 20px;*/
  border-radius: 10px;
  opacity: 0;
  transition: opacity 2s ease-out 0.5s;
}

.fade-in {
  opacity: 1;
}

.tileSection {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tile {
  flex: 1 0 300px;
  width: 300px;
  max-width: 300px;
  background-color: lightgray;
  color: black;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.6);
}

.list {
  text-align: left;
}

.list-item {
  margin-bottom: 10px;
}

.services-title {
	height: 100%;
	color: dark-gray;
}

@media (min-width: 1092px) {
  .services {
    padding-top: 0px;
		padding-bottom: 0px;
    min-height: 100px;
  }
}

@media (max-width: 768px) {
  .services {
    padding-top: 600px; /* Increase this value as needed */
    padding-bottom: 50px;
    height: fit-content;
    /*margin-bottom: 50px; *//* Add margin-bottom to create space between sections */
  }

	.services-title {
    font-size: 1.5rem; /* Adjust this value as needed */
		color: dark-gray;
  }

  .tileSection {
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .tile {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    background-color: lightgray;
    color: black;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.6);
  }
}
