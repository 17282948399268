.schedule {
	background-image: url('../images/bg3.jpg');
	padding-top: 50px;
	min-height: 400px;
}

.schedule-content {
	position: absolute;
	width: 65%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 20px;
	border-radius: 10px;
	opacity: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	background-color: rgba(255, 255, 255, 0.6);
	transition: opacity 1s ease-out 0.5s;
}

.fade-in {
	opacity: 1;
}

/* Form related styles */

.contact-form-tile {
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  text-align: center; /* center the contents horizontally */
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.form-group {
  flex-basis: calc(50% - 10px);
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
	align-items: left;
	text-align: left;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .form-group {
    flex-basis: 100%;
  }
}
