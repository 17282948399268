.aboutus {
  background-image: url('../images/bg2.jpg');
	min-height: 550px;
	padding-top: 100px;
}

.aboutus-title {
	text-align: center;
	width: 100%;
}

.aboutus-content {
  position: absolute;
  flex-wrap: wrap;
  width: 65%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	text-align: left;
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.6);
  transition: opacity 1s ease-out 0.5s;
}

.fade-in {
  opacity: 1;
}

.list {
  text-align: left;
}

.list-item {
  margin-bottom: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .aboutus {
    padding-top: 360px; /* Adjust this value as needed */
    padding-bottom: 50px;
    width: 100%;
    /*margin-bottom: 50px; *//* Add margin-bottom to create space between sections */
  }

  .aboutus-content {
    width: 90%; /* Increase width for mobile devices */
		padding-left: 20px;
		padding-right: 20px;
  }

  .aboutus-content p {
    text-align: left;
  }

  .list {
    text-align: left;
  }
}
